import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "WebPushAPI を用いたブラウザでのプッシュ通知開発 〜メドレー TechLunch〜",
  "date": "2017-07-13T08:00:00.000Z",
  "slug": "entry/2017/07/13/170000",
  "tags": ["medley"],
  "hero": "./2017_07_13.png",
  "heroAlt": "push"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`開発本部の`}<a parentName="p" {...{
        "href": "https://github.com/miyucy"
      }}>{`宮内`}</a>{`です。`}</p>
    <p>{`先日、社内勉強会「TechLunch」にて WebPushAPI についての発表を行いましたので、その紹介をさせていただきたいと思います。`}</p>
    <h1>{`WebPushAPI とは？`}</h1>
    <p>{`一般的にプロダクトにおいて、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A5%DE%A1%BC%A5%C8%A5%D5%A5%A9%A5%F3"
      }}>{`スマートフォン`}</a>{`アプリのような「プッシュ通知」を導入しようと思った場合、いままでは専用のアプリケーションを開発する必要がありました。`}</p>
    <p>{`しかし、プッシュ通知に関する`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/API"
      }}>{`API`}</a>{`が`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/W3C"
      }}>{`W3C`}</a>{`で標準化が進み(まだドラフト状態とはいえ)、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Google%20Chrome"
      }}>{`Google Chrome`}</a>{`のバージョン 42、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Mozilla%20Firefox"
      }}>{`Mozilla Firefox`}</a>{`のバージョン 44 に、WebPushAPI が導入され、元来ある Web アプリケーションに簡単にプッシュ通知を取り込むことが可能になりました。
詳しくは `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/fundamentals/getting-started/codelabs/push-notifications/"
      }}>{`ウェブアプリへのプッシュ通知の追加 `}</a>{`、`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/Push_API/Using_the_Push_API"
      }}>{`Using the Push API`}</a>{`や、`}<a parentName="p" {...{
        "href": "https://gihyo.jp/magazine/wdpress/archive/2017/vol97"
      }}>{`WEB+DB PRESS Vol.97`}</a>{` などを読むとより理解が深まるので興味ある方はぜひ読んでみてください。`}</p>
    <h1>{`TechLunch でやったこと`}</h1>
    <p>{`TechLunch では、自分が実際に実装した WebPushAPI のサンプルコードを元に、動作を示しながら挙動を開発本部の全員で追っていきました。`}</p>
    <iframe id="talk_frame_399319" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/3649c540494d48a6a95ae667b39370e8" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/webpushapiwoshi-tutemiyou">speakerdeck.com</a></cite>
    <p>{`具体的な手順などもスライドにありますが、公開したものだけだとちょっと分かりにくいかもですね。ごめんなさい。`}</p>
    <p>{`もっと詳しく聞きたいなって人がいたら、気軽に`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`ここらへん`}</a>{`にある「話を聞きに行きたい」ボタンを押下してみてください。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、口コミで探せる`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%B2%F0%B8%EE%BB%DC%C0%DF"
      }}>{`介護施設`}</a>{`の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CREATOR'S STORY | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fteam%2Fcreator-story.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/team/creator-story.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      